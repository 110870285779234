import { dom, library } from '@fortawesome/fontawesome-svg-core';

export default (function () {
    const far = '@fortawesome/pro-regular-svg-icons/fa';
    const fal = '@fortawesome/pro-light-svg-icons/fa';
    const fas = '@fortawesome/pro-solid-svg-icons/fa';
    const fad = '@fortawesome/pro-duotone-svg-icons/fa';
    const fab = '@fortawesome/free-brands-svg-icons/fa';

    // Regular Icons
    library.add(
        require(far.concat('Check')).definition,
        require(far.concat('SpinnerThird')).definition,
        require(far.concat('Times')).definition,
    );

    // Light Icons
    library.add(
        require(fal.concat('At')).definition,
        require(fal.concat('ArrowDown')).definition,
        require(fal.concat('ArrowLeft')).definition,
        require(fal.concat('ArrowRight')).definition,
        require(fal.concat('ArrowUp')).definition,
        require(fal.concat('Bell')).definition,
        require(fal.concat('Building')).definition,
        require(fal.concat('Calculator')).definition,
        require(fal.concat('Check')).definition,
        require(fal.concat('CheckCircle')).definition,
        require(fal.concat('CheckSquare')).definition,
        require(fal.concat('ChevronDown')).definition,
        require(fal.concat('ChevronLeft')).definition,
        require(fal.concat('ChevronRight')).definition,
        require(fal.concat('ChevronUp')).definition,
        require(fal.concat('ClipboardCheck')).definition,
        require(fal.concat('Cogs')).definition,
        require(fal.concat('CommentAlt')).definition,
        require(fal.concat('CommentExclamation')).definition,
        require(fal.concat('DollarSign')).definition,
        require(fal.concat('DotCircle')).definition,
        require(fal.concat('Envelope')).definition,
        require(fal.concat('EnvelopeOpen')).definition,
        require(fal.concat('ExclamationTriangle')).definition,
        require(fal.concat('File')).definition,
        require(fal.concat('FileCheck')).definition,
        require(fal.concat('FolderOpen')).definition,
        require(fal.concat('Globe')).definition,
        require(fal.concat('Info')).definition,
        require(fal.concat('List')).definition,
        require(fal.concat('Lock')).definition,
        require(fal.concat('Minus')).definition,
        require(fal.concat('MousePointer')).definition,
        require(fal.concat('Paperclip')).definition,
        require(fal.concat('Pencil')).definition,
        require(fal.concat('Phone')).definition,
        require(fal.concat('Plus')).definition,
        require(fal.concat('PoundSign')).definition,
        require(fal.concat('Question')).definition,
        require(fal.concat('QuestionCircle')).definition,
        require(fal.concat('Redo')).definition,
        require(fal.concat('Search')).definition,
        require(fal.concat('SpinnerThird')).definition,
        require(fal.concat('Tag')).definition,
        require(fal.concat('ThumbsDown')).definition,
        require(fal.concat('ThumbsUp')).definition,
        require(fal.concat('Times')).definition,
        require(fal.concat('Trash')).definition,
        require(fal.concat('TrashAlt')).definition,
        require(fal.concat('User')).definition,
        require(fal.concat('Message')).definition,
    );

    // Solid Icons
    library.add(
        require(fas.concat('AddressCard')).definition,
        require(fas.concat('AngleDown')).definition,
        require(fas.concat('AngleLeft')).definition,
        require(fas.concat('AngleRight')).definition,
        require(fas.concat('AngleUp')).definition,
        require(fas.concat('ArrowDown')).definition,
        require(fas.concat('ArrowLeft')).definition,
        require(fas.concat('ArrowRight')).definition,
        require(fas.concat('ArrowUp')).definition,
        require(fas.concat('Ban')).definition,
        require(fas.concat('CaretDown')).definition,
        require(fas.concat('CaretLeft')).definition,
        require(fas.concat('CaretRight')).definition,
        require(fas.concat('CaretUp')).definition,
        require(fas.concat('Check')).definition,
        require(fas.concat('ChevronDown')).definition,
        require(fas.concat('ChevronLeft')).definition,
        require(fas.concat('ChevronRight')).definition,
        require(fas.concat('ChevronUp')).definition,
        require(fas.concat('Coffee')).definition,
        require(fas.concat('Cog')).definition,
        require(fas.concat('Question')).definition,
        require(fas.concat('SpinnerThird')).definition,
        require(fas.concat('Times')).definition,
        require(fas.concat('Times')).definition,
        require(fas.concat('ExternalLinkAlt')).definition,
    );

    // Duotone Icons
    library.add(require(fad.concat('School')).definition, require(fad.concat('GraduationCap')).definition);

    library.add(
        require(fab.concat('Linkedin')).definition,
        require(fab.concat('Whatsapp')).definition,
        require(fab.concat('Microsoft')).definition,
        require(fab.concat('Google')).definition,
    );

    // Watch the DOM for <i>
    dom.watch();
});
